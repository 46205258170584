// https://chakra-ui.com/getting-started/gatsby-guide#usage

import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  colors: {
    primary: {
      100: "#E7EFFF",
      200: "#CAD8F2",
      300: "#ADC0E5",
      400: "#90A9D8",
      500: "#547ac4",
      600: "#3A62B2",
      700: "#1D4BA5",
      800: "#003398",
      900: "#001A4E",
    },
  },
});
