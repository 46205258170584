import Clarity from "@microsoft/clarity";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import React from "react";
import type {
  PossibleGatsbyCountry,
  PossibleGatsbyDomain,
} from "./src/live-sites";

import "./src/css/index.css";
import getUniqueId from "./src/utils/getUniqueId";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours (required for localStorage caching)
      staleTime: 1000 * 60 * 1, // 1 min
    },
  },
});

const clarityId = {
  "best-jobs-online.com": "p5kg6dwfz4",
  "just-jobs.com": "xxx",
}[process.env.GATSBY_DOMAIN as PossibleGatsbyDomain];
Clarity.init(clarityId);
Clarity.setTag("country", process.env.GATSBY_COUNTRY as PossibleGatsbyCountry);

persistQueryClient({
  queryClient,
  persister: createSyncStoragePersister({ storage: window.localStorage }),
});

export const wrapPageElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
  );
};

function logExceptionsToGA() {
  if (typeof window.onerror !== "object") return;
  // https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#notes
  window.onerror = function (msg, url, error) {
    const description = [
      "Message: " + msg,
      "URL: " + url,
      "Error object: " + JSON.stringify(error),
    ].join(" - ");

    if (window.gtag) {
      window.gtag("event", "exception", {
        description,
      });
    }
  };
}

export function onClientEntry() {
  logExceptionsToGA();
  const uniqueId = getUniqueId();
  Clarity.identify(uniqueId);
}
