import { v4 as uuidv4 } from "uuid";

let localStorageTestResult: boolean;

function supportsLocalStorage() {
  if (typeof localStorageTestResult === "boolean")
    return localStorageTestResult;
  try {
    if (!localStorage) return false;
    localStorage.setItem("testItem", "1");
    localStorage.removeItem("testItem");
    return true;
  } catch (err) {
    return false;
  }
}

export default function getUniqueId() {
  if (!supportsLocalStorage()) {
    return uuidv4();
  }
  const uniqueID = localStorage.getItem("uniqueId");
  if (uniqueID) {
    return uniqueID;
  }

  const newUniqueID = uuidv4();
  localStorage.setItem("uniqueId", newUniqueID);
  return newUniqueID;
}
